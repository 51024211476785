@import 'styles/utils/sizes';
@import 'styles/utils/mixins';
@import 'styles/utils/colors';

.section {
  padding: 60px 16px;

  .titleStyle {
    [data-title] {
      @include fontSize(38px, 130%);

      font-weight: 600;

      @include below-tablet {
        @include fontSize(24px, 150%);
      }
    }

    [data-description] {
      @include fontSize(20px, 150%);

      color: $black-alpha;

      @include below-tablet {
        @include fontSize(16px, 150%);
      }
    }
  }

  .contentWrapper {
    margin: 0 auto;
    max-width: $default-content-width;
  }

  .project-wrapper {
    position: relative;
    display: flex;
    aspect-ratio: 990/700;
    margin-bottom: 24px;
    border-radius: 24px;
    overflow: hidden;

    &:hover .overlay {
      opacity: 1;
      visibility: visible;
    }
  }

  .project {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .overlay {
    @include flex(column, flex-end, flex-start);

    position: absolute;
    left: 0;
    top: 0;
    padding: 24px;
    width: 100%;
    height: 100%;
    bottom: 0;
    border-radius: 24px;
    opacity: 0;
    visibility: hidden;
    transition: all ease-in-out 0.5s;
    box-shadow: inset 20px 20px 300px 100px rgba($color: $gray900, $alpha: 35%);

    h4 {
      @include fontSize(24px, 150%);

      color: $gray0;
      font-weight: 600;

      @include phone {
        @include fontSize(16px, 150%);
      }
    }

    p {
      @include fontSize(16px, 150%);

      color: $gray0;

      @include phone {
        @include fontSize(14px, 150%);
      }
    }
  }

  .imagesList {
    @include flex(column);

    gap: 24px;

    & > div {
      width: 100%;
    }
  }

  .link {
    @include flex(row, center, center);

    margin: 48px auto 0;
    border-radius: 50%;
    border: 1px solid $gray900;
    width: 160px;
    height: 160px;

    &:hover {
      background-color: $yellow200;
    }
  }

  @include phone {
    padding: 30px 16px;
  }
}
